import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useRootStore } from "../../stores/storeContext";
import { ClIconSort, ClIconNoResult } from "../shared/cl-icon";
import SortListIcon from "../../assets/icons/sort-list.svg";
import NoResultIcon from "../../assets/icons/no-result.svg";
import {
  sortDirection,
  homeHookupPatientsSort
} from "../../boot/Constants";
import { CLSpiner } from "../shared/cl-spinner";
import ContentLoader from "react-content-loader";

import { CLPatientListTableWraper } from "../cl-patients-list-table/cl-patients-list-table.presents";

export const CLPatientHomeHookupListTable = observer(() => {
  const {
    itemsHomeHookup,
    isLoading,
    setSortByHookup,
    isNoPatients
  } = useRootStore().listPatientsStore;

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortByHookup(sortBy, sortDirection);
  };

  const getLoadContent = () => {
    const items = [];
    for (let i = 0; i < 6; i++) {
      items.push(<tr key={i.toString()}>
        <td>
          <ContentLoader title="" width="145" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="105" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
      </tr>);
    }
    return items;
  };
  return (
    <CLPatientListTableWraper>
      <Table>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-title">Patient Name</div>
                <div className="sort-actions">
                  <div onClick={() => sort(homeHookupPatientsSort.patientname, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div
                    onClick={() => sort(homeHookupPatientsSort.patientname, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div className="sort-title">Hookup Status</div>
              </div>
            </th>
            <th>
              <div>
                <div className="sort-title">Hookup Start Date</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Serial</div>
                <div className="sort-actions">
                  <div onClick={() => sort(homeHookupPatientsSort.serial, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(homeHookupPatientsSort.serial, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div className="sort-title">Status</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Recording Id</div>
                <div className="sort-actions">
                  <div onClick={() => sort(homeHookupPatientsSort.recordingid, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div
                    onClick={() => sort(homeHookupPatientsSort.recordingid, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Ordering Physician</div>
                <div className="sort-actions">
                  <div onClick={() => sort(homeHookupPatientsSort.physician, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div
                    onClick={() => sort(homeHookupPatientsSort.physician, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div className="sort-title">Mode</div>
              </div>
            </th>
            <th className="sort">
              <div className="sort-title">Duration</div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Tracking Number</div>
                <div className="sort-actions">
                  <div onClick={() => sort(homeHookupPatientsSort.trackingnumber, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div
                    onClick={() => sort(homeHookupPatientsSort.trackingnumber, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Device Clinic</div>
                <div className="sort-actions">
                  <div onClick={() => sort(homeHookupPatientsSort.deviceclinic, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div
                    onClick={() => sort(homeHookupPatientsSort.deviceclinic, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>

        {(!isLoading && itemsHomeHookup.length > 0) && <tbody>
          {itemsHomeHookup.map((item: any) => <tr className="cursor">
            <td>{item.FullName}</td>
            <td
              className={item.HomeHookupStatus === "Cancelled" ? "red" : ""}>{item.HomeHookupStatus}</td>
            <td>{item.Start}</td>
            <td>{item.Serial}</td>
            <td>{item.DeviceStatus}</td>
            <td>{item.RecordingId}</td>
            <td>{item.OrderingPhysician}</td>
            <td>{item.Mode}</td>
            <td>{item.Duration}</td>
            <td>{item.TrackingNumber}</td>
            <td>{item.DeviceClinic}</td>
          </tr>
          )}
        </tbody>}
        {isLoading && <tbody>
          {getLoadContent()}
          <tr><td><div className="spiner"><CLSpiner></CLSpiner></div></td></tr>
        </tbody>}
      </Table>
      {(!isLoading && !itemsHomeHookup.length)
        && <div className="no-result-section">
          {!isNoPatients() && <div className="no-result-content">
            <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
            <div className="text">Sorry, no results found</div>
          </div>}
        </div>}
    </CLPatientListTableWraper>
  );
});

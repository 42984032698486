import { types, flow } from "mobx-state-tree";
import { IObservableArray } from "mobx";
import { listReportsSort, sortDirection, reportsListCountRows } from "../boot/Constants";
import {
  getTotalClinicRepotsCountApi,
  getListClinicRepotsApi,
  downloadReportApi,
  setPrintedApi
} from "../services/api/report.api";
import moment from "moment";
import { openNewTabBlob } from "../services/handlers/data.handler";
import { getAllClinicPhysiciansApi } from "../services/api/clinic.api";

export const ListReportsModel = types
  .model("ListReportsModel", {
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, listReportsSort.reportdate),
    sortDirection: types.optional(types.number, sortDirection.DESC),
    startDate: types.maybeNull(types.optional(types.string, moment().subtract(6, "months").format("MM/DD/yyyy"))),
    endDate: types.maybeNull(types.optional(types.string, moment().format("MM/DD/yyyy"))),
    items: types.optional(types.array(types.frozen()), []),
    isLoading: types.optional(types.boolean, true),
    physiciansFilterOptions: types.optional(types.array(types.frozen()), []),
    selectedPhysicians: types.optional(types.array(types.string), []),
    modeOptions: types.optional(types.array(types.frozen()), []),
    selectedMode: types.optional(types.array(types.string), ["0"]),
    signedOptions: types.optional(types.array(types.frozen()), []),
    selectedSigned: types.optional(types.array(types.string), ["0"])
  })
  .actions(self => ({
    setList(dto: any[]) {
      self.items = dto as IObservableArray;
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setPhusiciansFilterOptions(dto: any[]) {
      self.physiciansFilterOptions = dto as IObservableArray;
    },
    setSelectedPhysicians(dto: any[]) {
      self.selectedPhysicians = dto as IObservableArray;
    },
    setModeOptions(dto: any[]) {
      self.modeOptions = dto as IObservableArray;
    },
    setSelectedMode(dto: any[]) {
      self.selectedMode = dto as IObservableArray;
    },
    setSignedOptions(dto: any[]) {
      self.signedOptions = dto as IObservableArray;
    },
    setSelectedSigned(dto: any[]) {
      self.selectedSigned = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const defaultPage = (skipRequest?: boolean) => {
      self.page = 0;
      !skipRequest && getList();
    };

    const setDate = (start: string, end: string) => {
      self.startDate = start;
      self.endDate = end;
    };

    const getList = flow(function* getList() {
      self.setLoading(true);
      const rows = self.total && ((self.page * reportsListCountRows + reportsListCountRows) > self.total)
        ? self.total - (self.page * reportsListCountRows)
        : reportsListCountRows;

      const filter: any = {
        Offset: self.page * reportsListCountRows,
        CountRows: rows,
        Find: {}
      };
      const filterMode = self.selectedMode + "" || "0";
      const filterSigned = self.selectedSigned + "" || "0";

      if (self.searchValue) {
        filter.Find.patientname = self.searchValue;
        filter.Find.patientid = self.searchValue;
        filter.Find.recordingid = self.searchValue;
        filter.Find.physician_ordering = self.searchValue;
        filter.Find.physician_reading = self.searchValue;
      };

      if (self.startDate && self.endDate) {
        filter.Find.reportdate_start = self.startDate;
        filter.Find.reportdate_end = self.endDate;
      }

      if (self.sortBy && self.sortDirection) {
        filter.SortBy = self.sortBy;
        filter.SortDirection = self.sortDirection;
      }

      if (self.selectedPhysicians.length) {
        filter.WithPhysician = self.selectedPhysicians;
      }

      try {
        const result = yield getListClinicRepotsApi(filter, filterMode, filterSigned);
        if (result.ok) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      const filter: any = {
        Find: {}
      };
      const filterMode = self.selectedMode + "" || "0";
      const filterSigned = self.selectedSigned + "" || "0";
      if (self.searchValue) {
        filter.Find.patientname = self.searchValue;
        filter.Find.patientid = self.searchValue;
        filter.Find.recordingid = self.searchValue;
        filter.Find.physician_ordering = self.searchValue;
        filter.Find.physician_reading = self.searchValue;
      };

      if (self.startDate && self.endDate) {
        filter.Find.reportdate_start = self.startDate;
        filter.Find.reportdate_end = self.endDate;
      }

      if (self.selectedPhysicians.length) {
        filter.WithPhysician = self.selectedPhysicians;
      }

      try {
        self.setLoading(true);
        const result = yield getTotalClinicRepotsCountApi(filter, filterMode, filterSigned);
        if (result.ok) {
          self.total = result.data || 0;
          getList();
        } else {
          self.setLoading(false);
        }
      } catch (error) {
        self.setLoading(false);
        console.error(error);
      }
    });

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const openReport = flow(function* downloadReport(reportID: string | number) {
      const result = yield downloadReportApi(reportID);
      if (result.ok) {
        const blob = new Blob([result.data], { type: "application/pdf" });
        openNewTabBlob(blob);
      }
    });

    const setPrinted = flow(function* setPrinted(reportID: string | number, isPrinted: boolean) {
      try {
        self.setLoading(true);
        const result = yield setPrintedApi(reportID, isPrinted);
        if (result.ok) {
          getTotalCount();
        } else {
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setLoading(false);
      }
    });

    const getAllClinicPhysicians = flow(function* getAllClinicPhysicians() {
      const result = yield getAllClinicPhysiciansApi();
      if (result.ok && result.data.length) {
        const data = result.data.map((item: any) => {
          return {
            value: item.Id,
            label: `${item.Name} ${item.LastName}`
          };
        });
        self.setPhusiciansFilterOptions(data);
        return data;
      }
      return [];
    });

    const selectePractice = (data: any) => {
      // self.setSelectedPhysicians(data.map((item: any) => { return item.label; }));
      if (data && data.value) {
        self.setSelectedPhysicians([data.value + ""]);
      } else {
        self.setSelectedPhysicians([]);
      }
      getTotalCount();
    };
    const selecteMode = (data: any) => {
      if (data && data.value) {
        self.setSelectedMode([data.value + ""]);
      } else {
        self.setSelectedMode([]);
      }
      getTotalCount();
    };
    const selecteSigned = (data: any) => {
      if (data && data.value) {
        self.setSelectedSigned([data.value + ""]);
      } else {
        self.setSelectedSigned([]);
      }
      getTotalCount();
    };

    return {
      nextPage,
      previousPage,
      defaultPage,
      getList,
      getTotalCount,
      setSearch,
      setSortBy,
      setDate,
      getAllClinicPhysicians,
      openReport,
      setPrinted,
      selectePractice,
      selecteMode,
      selecteSigned
    };
  })
  .actions((self) => ({
    afterCreate() {
      self.setModeOptions([{
        value: "0",
        label: "All Modes"
      }, {
        value: "1",
        label: "MCT"
      }, {
        value: "2",
        label: "Event Recorder"
      }, {
        value: "3",
        label: "Wireless Holter"
      }, {
        value: "4",
        label: "Holter+ MCT"
      }]);
      self.setSignedOptions([{
        value: "0",
        label: "All Reports"
      }, {
        value: "1",
        label: "To Be Signed"
      }, {
        value: "2",
        label: "Signed"
      }]);
    }
  }));

import { OptionType, PropsType } from "./cl-select.props";
import { SelectStyle } from "./cl-select.presents";
import Select from "react-select";

const getSelect = (props: PropsType, type?: string) => {
  const onSelect = (option: any) => {
    props.selectOption(option);
  };
  const onMenuOpen = () => {
    props.onMenuOpen && props.onMenuOpen();
  };
  if (props.isMulti && props.dynamicMulti) {
    const defVal: OptionType[] = [];
    if (props.defaultValue) {
      props.defaultValue.forEach((value: string) => {
        const selected = props.options.find((item) => value === item.value);
        if (selected) {
          defVal.push(selected);
        }
      });
    }
    return (
      <SelectStyle width={props.width} padding={props.padding} type={type}>
        <Select
          isMulti={props.isMulti}
          closeMenuOnSelect={!props.isMulti}
          isSearchable={props.isSearchable !== false}
          hideSelectedOptions={!props.showSelectedOptions}
          options={props.options}
          onChange={onSelect}
          onMenuOpen={onMenuOpen}
          noOptionsMessage={() => props.noOptionsMsg ?? "No options"}
          value={defVal}
          name={props.name || "select"}
          isDisabled={props.isDisabled}
          classNamePrefix="cl-select"
          placeholder={props.placeholder ? props.placeholder : "Select..."}
        ></Select>
      </SelectStyle>
    );
  }

  const selectProps: any = {
    isMulti: props.isMulti,
    isSearchable: props.isSearchable !== false,
    options: props.options,
    onChange: onSelect,
    onMenuOpen: onMenuOpen,
    hideSelectedOptions: !props.showSelectedOptions,
    defaultValue: props.defaultValue,
    name: props.name || "select",
    isClearable: props.isClearable,
    isDisabled: props.isDisabled,
    classNamePrefix: "cl-select",
    placeholder: props.placeholder ? props.placeholder : "Select..."
  };

  if (props.value) {
    selectProps.value = props.value;
  }
  return (
    <SelectStyle width={props.width} padding={props.padding} type={type}>
      <Select
        {...selectProps}
        noOptionsMessage={() => props.noOptionsMsg || "No options"}></Select>
    </SelectStyle>
  );
};

export const CLSelect = (props: PropsType) => {
  return getSelect(props);
};

export const CLSelectSmall = (props: PropsType) => {
  return getSelect(props, "smallSelect");
};

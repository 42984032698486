import { useRootStore } from "../../stores/storeContext";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component";
import { useEffect, useRef, useState } from "react";
import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { CLSpiner } from "../shared/cl-spinner";
import { useParams } from "react-router-dom";
import { ClPatientInsuranceInfoWrapper } from "./cl-patient-insurance-info.presents";
import { CLUploadInsuranceCardImages } from "../cl-upload-insurance-card-images";
import { message } from "antd";
import { DeviceStatuses } from "../../boot/Constants";

export const ClPatientInsuranceInfo = observer(() => {
  const {
    resetForm,
    isCheckingValid,
    validateFormFields,
    primaryInsuranceCompany,
    policyNumber,
    groupNumber,
    secondaryInsuranceCompany,
    secPolicyNumber,
    secGroupNumber,
    subscribersFirstName,
    specifyRelationship,
    subscribersLastName,
    subscribersRelationship,
    setDisableCompany,
    subscribersDOB,
    isLoading,
    saveForm
  } = useRootStore().patientCreateAdditionalModel;
  const { patientBase } = useRootStore().patientInfoStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const [highlights, setIsHighlights] = useState(false);
  const status = patientBase?.Device?.Status;

  const history: any = useHistory();
  const { patientId }: any = useParams();
  const fileRef = useRef<{ submitFile: Function, validateFile: Function }>(null);

  function validateFormHighLight() {
    const { errors, isValid } = validateFormFields(false);

    setIsHighlights(!isValid);
    setErrors(errors);
  }
  const isSpecifyRelationshipShow = subscribersRelationship.value === "Other";
  const isCreate = history?.location?.state?.isCreate;
  const isStudy = history?.location?.state?.isStudy;

  const isSaveIncompleateAvailable
    = !(DeviceStatuses.Standby === status
    || DeviceStatuses.Ready === status
    || DeviceStatuses.Recording === status
    || DeviceStatuses.Configured === status);

  useEffect(() => {
    if (!isCheckingValid && isStudy) {
      validateFormHighLight();
    }
  }, [isCheckingValid, isStudy]);

  useEffect(() => {
    if (subscribersRelationship?.defaultValue?.value === "Self"
      && patientBase?.Id
      && subscribersRelationship?.value === "") {
      prepopulateFields(true);
      subscribersRelationship.setValue("Self");
    }
  }, [patientBase, subscribersRelationship]);

  function validateForm() {
    const { errors, isValid } = validateFormFields(isSaveIncompleateAvailable);
    setErrors(errors);
    return isValid;
  }

  function prepopulateFields(checkIsValueExist?: boolean) {
    checkIsValueExist
      ? !subscribersFirstName.value && subscribersFirstName.setValue(patientBase.Name)
      : subscribersFirstName.setValue(patientBase.Name);
    checkIsValueExist
      ? !subscribersLastName.value && subscribersLastName.setValue(patientBase.LastName)
      : subscribersLastName.setValue(patientBase.LastName);
    checkIsValueExist
      ? !subscribersDOB.value && subscribersDOB.setValue(patientBase.DOB)
      : subscribersDOB.setValue(patientBase.DOB);
  }

  const handleChangeInsurance = (event: any, item: any) => {
    const value = event?.value || "";
    if (item.fieldId === "primaryInsuranceCompany") {
      setDisableCompany(value, true);
    } else {
      setDisableCompany(value, false);
    }
  };

  const handleChange = () => {
  };
  const handleChangeRelationShip = (event: any, item: any) => {
    const selectedRelationship = item.value;

    if (selectedRelationship === "Self") {
      prepopulateFields();
    } else {
      subscribersFirstName.setValue("");
      subscribersLastName.setValue("");
      subscribersDOB.setValue("");
      specifyRelationship.setValue("");
    }
  };
  const clearData = () => {
    resetForm(true);
    setFormInvalid(false);
    setErrors({});
  };
  const hide = () => {
    clearData();
    history.goBack();
  };
  const onSubmit = async(event: any) => {
    event.preventDefault();
    const key = "updatable";
    const id = patientId || "";
    if (!validateForm()) {
      setFormInvalid(true);
    } else {
      try {
        setFormInvalid(false);
        setGlobalSpiner(true);
        const response = await saveForm();
        if (response) {
          await fileRef.current?.submitFile(id, !isCreate);
          if (isCreate) {
            setTimeout(() => {
              clearData();
              history.push(`/study-create/${id}`);
            }, 100);
          } if (isStudy) {
            hide();
          } else {
            setFormInvalid(false);
            setErrors({});
            message.success({ content: "Insurance information successfully updated!", key, duration: 2 });
          }
        } else {
          message.error({ content: "Error, Something went wrong!", key, duration: 2 });
        }
        setGlobalSpiner(false);
      } catch (e) {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
      }
    }
  };

  return (
    <ClPatientInsuranceInfoWrapper>
      <div className="container">
        <h1>Insurance</h1>
        {isLoading
          ? <div className="spinner"><CLSpiner containerWidth={"100px"}/></div>
          : <div className={"form-container " + (highlights ? "highlight" : "")} id="study-enrollment">
            <form onSubmit={onSubmit}>
              <div className="study-create-block">
                <h3>Insurance Information</h3>
                <CLDynamicFormComponent
                  item={primaryInsuranceCompany}
                  isClearable={true}
                  noOptionsMsg={"If your insurance is not found, contact RhythmExpress to add it"}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChangeInsurance}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={policyNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={groupNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <div className="w100 border-bottom"></div>
                <h3>Secondary Insurance Information</h3>
                <CLDynamicFormComponent
                  item={secondaryInsuranceCompany}
                  noOptionsMsg={"If your insurance is not found, contact RhythmExpress to add it"}
                  isClearable={true}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChangeInsurance}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={secPolicyNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={secGroupNumber}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <div className="w100 border-bottom"></div>
                <h3>Subscriber Information</h3>
                <CLDynamicFormComponent
                  item={subscribersRelationship}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChangeRelationShip}
                  isFormInvalid={formInvalid}/>
                {isSpecifyRelationshipShow
                  ? <CLDynamicFormComponent
                    item={specifyRelationship}
                    customClass="invite-field"
                    errors={errors}
                    handleChange={handleChange}
                    isFormInvalid={formInvalid}/>
                  : null}
                <CLDynamicFormComponent
                  item={subscribersFirstName}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={subscribersLastName}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
                <CLDynamicFormComponent
                  item={subscribersDOB}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}/>
              </div>
              <div className="w100 border-bottom"></div>
              <CLUploadInsuranceCardImages ref={fileRef} idPatient={patientId || 0} isEdit={!isCreate}/>
              <div className="btn-container">
                <CLBlueButton type="submit" text={"Save Information"} click={() => {
                }}/>
                <CLLightButton type="button" text="Cancel" click={hide}/>
              </div>
            </form>
          </div>
        }
      </div>
    </ClPatientInsuranceInfoWrapper>
  );
});

import { types, flow } from "mobx-state-tree";
import { IObservableArray } from "mobx";
import {
  AdministrationListCategories,
  administrationListCountRows,
  sortDirection,
  listAdministrationSort
} from "../boot/Constants";
import {
  getTotalAdministrationCountApi,
  getListAdministrationApi,
  deleteAdminApi,
  changeRoleApi, getClinicCellPhoneApi, saveClinicCellPhoneApi
} from "../services/api/administration.api";

export const ListAdministrationModel = types
  .model("ListAdministrationModel", {
    activeCategory: types.optional(types.string, "all"),
    page: 0,
    total: 0,
    allCategoryTotal: types.optional(types.number, 0),
    physiciansCategoryTotal: types.optional(types.number, 0),
    usersCategoryTotal: types.optional(types.number, 0),
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, listAdministrationSort.name),
    sortDirection: types.optional(types.number, sortDirection.ASC),
    items: types.optional(types.array(types.frozen()), []),
    phoneNumber: types.optional(types.string, ""),
    isLoading: types.optional(types.boolean, true)
  })
  .actions(self => ({
    setList(dto: any[]) {
      self.items = dto as IObservableArray;
    },
    setPhoneNumber(phone: number | string) {
      self.phoneNumber = phone + "" || "";
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      getList();
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const getClinicPhoneNumber = flow(function* getTotalCount() {
      try {
        //
        const result = yield getClinicCellPhoneApi();
        if (result.ok) {
          self.setPhoneNumber(result.data);
        }
      } catch (error) {
        console.error(error);
      }
    });

    const saveClinicPhoneNumber = flow(function* getTotalCount(phone: string | number) {
      try {
        const result = yield saveClinicCellPhoneApi(phone);
        if (result.ok) {
          return true;
        }
      } catch {
        return false;
      }
    });

    const getList = flow(function* getList() {
      self.setLoading(true);
      const rows = self.total && ((self.page * administrationListCountRows + administrationListCountRows) > self.total)
        ? self.total - (self.page * administrationListCountRows)
        : administrationListCountRows;

      const filter: any = {
        Offset: self.page * administrationListCountRows,
        CountRows: rows
      };

      if (self.searchValue) {
        filter.Find = {
          name: self.searchValue,
          lastname: self.searchValue,
          email: self.searchValue,
          phone: self.searchValue
        };
      }

      if (self.sortBy && self.sortDirection) {
        filter.SortBy = self.sortBy;
        filter.SortDirection = self.sortDirection;
      }

      switch (self.activeCategory) {
        case AdministrationListCategories[1].value :
          filter.Find
            ? filter.Find.isphysician = true
            : filter.Find = { isphysician: true };
          break;
        case AdministrationListCategories[2].value :
          filter.Find
            ? filter.Find.isphysician = false
            : filter.Find = { isphysician: false };
          break;
        default:
          break;
      }

      try {
        const result = yield getListAdministrationApi(filter);
        if (result.ok) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      const filter: any = {
      };

      if (self.searchValue) {
        filter.Find = {
          name: self.searchValue,
          lastname: self.searchValue,
          email: self.searchValue,
          phone: self.searchValue
        };
      }

      try {
        const result = yield getTotalAdministrationCountApi(filter);
        if (result.ok) {
          self.allCategoryTotal = result.data.All || 0;
          self.physiciansCategoryTotal = result.data.Physicians || 0;
          self.usersCategoryTotal = result.data.Users || 0;

          switch (self.activeCategory) {
            case AdministrationListCategories[0].value :
              self.total = self.allCategoryTotal;
              break;
            case AdministrationListCategories[1].value :
              self.total = self.physiciansCategoryTotal;
              break;
            case AdministrationListCategories[2].value :
              self.total = self.usersCategoryTotal;
              break;
            default:
              break;
          }
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const deleteAdmin = flow(function* deleteAdmin(user: any) {
      try {
        const str = JSON.stringify(user);
        const result = yield deleteAdminApi(str);
        if (result.ok) {
          getTotalCount();
          return true;
        }
        return false;
      } catch (error) {
        console.log(error);
        return false;
      }
    });

    const changeRole = flow(function* changeRole(id: string | number, inviting: boolean) {
      self.setLoading(true);
      try {
        const result = yield changeRoleApi(id, inviting);
        self.setLoading(false);
        if (result.ok) {
          getTotalCount();
          return true;
        }
        return false;
      } catch (error) {
        self.setLoading(false);
        console.log(error);
        return false;
      }
    });

    return {
      nextPage,
      previousPage,
      getList,
      setActiveCategory,
      getTotalCount,
      getClinicPhoneNumber,
      saveClinicPhoneNumber,
      setSearch,
      setSortBy,
      deleteAdmin,
      changeRole
    };
  });

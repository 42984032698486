import { HeaderMobileStyle } from "./cl-app-header-mobile.presents";
import { theme } from "../../theme/theme";
import LogoImgAccess from "../../assets/img/rhythm-access.png";
import LogoImgRoc from "../../assets/img/roc-logo.png";
import LogoImgFmc from "../../assets/img/fmc-logo.png";
import ExitIcon from "../../assets/icons/exit.svg";
import DropdownIcon from "../../assets/icons/dropdown.svg";
import UserIcon from "../../assets/icons/list-patients-all.svg";
import { CLDropdown } from "../shared/cl-dropdown";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { CLSelect } from "../shared/cl-select";
import { FCMID, HeaderTabs, ROCTELID } from "../../boot/Constants";
import { useState } from "react";

export const ClAppHeaderMobile = observer(() => {
  const {
    logout,
    firstName,
    lastName,
    email,
    setDesktopMode,
    clinics,
    center,
    selectCenter,
    selectClinic,
    PortalUserMC,
    usersGroupSTR,
    PortalUserAC,
    IdCurrentMC,
    isCenterAvailable
  } = useRootStore().userStore;
  const [settingsEnable, setSettingsEnable] = useState(false);

  const history = useHistory();
  const { setGlobalSpiner } = useRootStore().globalStore;
  const {
    getTotalCount: getTotalCountPatients
  } = useRootStore().listPatientsStore;
  const { getTotalCount: getTotalCountDevices } = useRootStore().listDevicesStore;
  const { getTotalCount: getTotalCountAdministration } = useRootStore().listAdministrationStore;
  const { getTotalCount: getTotalCountReports } = useRootStore().listReportsStore;

  const isROCMC = IdCurrentMC + "" === ROCTELID + "";
  const isFMC = IdCurrentMC + "" === FCMID + "";

  const profileActions = [{
    text: firstName || lastName ? `${firstName} ${lastName} (${usersGroupSTR})` : `${email}`,
    color: theme.colors.text,
    action: () => {}
  }, {
    text: "Web Mode",
    color: theme.colors.text,
    action: () => {
      setDesktopMode();
      window.location.reload();
    }
  }, {
    text: "Exit",
    color: theme.colors.red,
    icon: ExitIcon,
    action: () => {
      logout();
      history.push("/login");
    }
  }];

  const centerSelected = async(event: any) => {
    setGlobalSpiner(true);
    await selectCenter(event.value);
    if (location.pathname === HeaderTabs[0].link) {
      await getTotalCountPatients();
    } else if (location.pathname === HeaderTabs[1].link) {
      await getTotalCountDevices();
    } else if (location.pathname === HeaderTabs[3].link) {
      await getTotalCountReports();
    } else if (location.pathname === HeaderTabs[4].link) {
      await getTotalCountAdministration();
    } else {
      setGlobalSpiner(false);
    }

    setTimeout(() => {
      setGlobalSpiner(false);
    }, 500);
  };

  const clinicianSelected = async(event: any) => {
    setGlobalSpiner(true);
    selectClinic(event.value);
    if (location.pathname === HeaderTabs[0].link) {
      await getTotalCountPatients();
    } else if (location.pathname === HeaderTabs[1].link) {
      await getTotalCountDevices();
    } else if (location.pathname === HeaderTabs[3].link) {
      await getTotalCountReports();
    } else if (location.pathname === HeaderTabs[4].link) {
      await getTotalCountAdministration();
    } else {
      setGlobalSpiner(false);
    }

    setTimeout(() => {
      setGlobalSpiner(false);
    }, 500);
  };

  const renderLogo = () => {
    if (isROCMC) return (<img className="logo" src={LogoImgRoc} alt=""></img>);
    if (isFMC) return (<img className="logo" src={LogoImgFmc} alt=""></img>);
    return (<img className="logo" src={LogoImgAccess} alt=""></img>);
  };

  const iconClass = settingsEnable ? "active" : "";
  return (
    <HeaderMobileStyle shadow={true}>
      <div className="container">
        {renderLogo()}
        <div className="left-element">
        </div>
        <div className="icon-settings" onClick={() => setSettingsEnable((prevState) => !prevState)}>
          <p>Clinic/Center</p>
          <img className={iconClass} src={DropdownIcon} alt=""></img>
        </div>
        <CLDropdown
          iconMain={UserIcon}
          actions={profileActions}
          rotateIcon={true}></CLDropdown>
      </div>
      {settingsEnable && <div className="settings-container">
        {isCenterAvailable && <div className="notification">
          {center.length > 0 && <div>
            <p className="label">Monitoring Center</p>
            <CLSelect
              width={"160px"}
              options={center}
              defaultValue={center.find((item: any) => item.value === PortalUserMC)}
              selectOption={centerSelected}></CLSelect>
          </div>}
        </div>}
        <div className="notification">
          {clinics.length > 0 && <div>
            <p className="label">Clinics</p>
            <CLSelect
              width={"160px"}
              options={clinics}
              defaultValue={clinics.find((item: any) => item.value === PortalUserAC)}
              value={clinics.find((item: any) => item.value === PortalUserAC)}
              // defaultValue={defaultValueClinicSelect}
              selectOption={clinicianSelected}></CLSelect>
          </div>}
        </div>
      </div>}
    </HeaderMobileStyle>
  );
});

import { types, applySnapshot, flow, Instance, SnapshotIn } from "mobx-state-tree";
import { UserFormModel } from "./createUser/user.model";
import { AdditionalInfoInitialState } from "./createUser/additional-info-initial.state";
import { validatePhoneHandler } from "../services/handlers/validate.handler";
import {
  getInsuranceCompaniesApi,
  getPatientAdditionalInfoApi,
  updatePatientAdditionalInfoApi
} from "../services/api/clinic.api";
import moment from "moment/moment";

let latestInitialState: any = null;
function updateLatestInitialState(state: any) {
  latestInitialState = state;
}
let insuranceCompanies: any[] = [];
export const PatientCreateAdditionalModel = types
  .model("InviteAdminModel", {
    show: types.optional(types.boolean, false),
    isValid: types.optional(types.boolean, false),
    isCheckingValid: types.optional(types.boolean, false),
    idUser: types.optional(types.string, ""),
    isLoading: types.optional(types.boolean, false),
    recordingId: types.optional(types.string, ""),
    id: types.optional(types.string, ""),
    CPTCodeId: types.maybe(UserFormModel),
    primaryInsuranceCompany: types.maybe(UserFormModel),
    policyNumber: types.maybe(UserFormModel),
    groupNumber: types.maybe(UserFormModel),
    secondaryInsuranceCompany: types.maybe(UserFormModel),
    secPolicyNumber: types.maybe(UserFormModel),
    secGroupNumber: types.maybe(UserFormModel),
    subscribersFirstName: types.maybe(UserFormModel),
    subscribersLastName: types.maybe(UserFormModel),
    subscribersRelationship: types.maybe(UserFormModel),
    specifyRelationship: types.maybe(UserFormModel),
    subscribersDOB: types.maybe(UserFormModel),
    insuranceChoiceInfo: types.optional(types.string, "")
  })
  .actions(self => ({
    setDefaultValueByKey(key: string, defaultValue: any) {
      if (self[key as keyof typeof self]) {
        self[key as keyof typeof self].value = defaultValue.value;
        self[key as keyof typeof self].defaultValue = defaultValue;
      }
    },
    selLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    selIsValid(isValid: boolean) {
      self.isValid = isValid;
    },
    setIsCheckingValid(isCheckingValid: boolean) {
      self.isCheckingValid = isCheckingValid;
    },
    setInsuranceChoiceInfo(val: string) {
      self.insuranceChoiceInfo = val;
    }
  }))
  // .actions(self => ({
  //   getData() {
  //     return getSnapshot(self);
  //   }
  // }))
  .actions(self => {
    const applyInsuranceCompanies = async() => {
      const companiesResult = await getInsuranceCompaniesApi();
      if (companiesResult.ok && companiesResult.data) {
        insuranceCompanies = companiesResult.data.map((item: any) => ({
          value: item.CompanyName + "",
          label: item.CompanyName + "",
          isDisabled: false
        }));

        self.primaryInsuranceCompany?.setOptions(insuranceCompanies);
        self.secondaryInsuranceCompany?.setOptions(insuranceCompanies);
      }
    };
    const setDisableCompany = (companyName: string, isPrimary: boolean) => {
      if (insuranceCompanies?.length) {
        isPrimary && self.secondaryInsuranceCompany?.options.map((item: any) => {
          item.isDisabled = item.value === companyName;
          return item;
        });
        !isPrimary && self.primaryInsuranceCompany?.options.map((item: any) => {
          item.isDisabled = item.value === companyName;
          return item;
        });
      }
    };
    return {
      applyInsuranceCompanies,
      setDisableCompany
    };
  })
  .actions(self => {
    const resetForm = (resetToDefault?: boolean) => {
      if (resetToDefault && latestInitialState) {
        apply(latestInitialState);
      } else {
        applySnapshot(self, AdditionalInfoInitialState);
      }
    };
    const closeModal = () => {
      resetForm();
    };

    const getDataForSave = () => {
      const getValue = (name: keyof typeof self) => {
        if (self[name]) {
          return self[name].value;
        }
        return null;
      };

      let dobValue = getValue("subscribersDOB");

      if (dobValue === "01/01/0001") {
        dobValue = "-";
      }

      const data: any = {
        // CPTCodeId: getValue("CPTCodeId"),
        PrimInsCompany: getValue("primaryInsuranceCompany"),
        PrimInsIDNumber: getValue("policyNumber"),
        PrimInsGroupNumber: getValue("groupNumber"),
        SecInsCompany: getValue("secondaryInsuranceCompany"),
        SecInsIDNumber: getValue("secPolicyNumber"),
        SecInsGroupNumber: getValue("secGroupNumber"),
        SubscFirstName: getValue("subscribersFirstName"),
        SubscLastName: getValue("subscribersLastName"),
        SubscRelationship: getValue("subscribersRelationship"),
        SpecifyRelationship: getValue("specifyRelationship"),
        SubscDOB: dobValue
      };
      const returnObj: any = {};
      for (const key in data) {
        if (data[key]) {
          returnObj[key] = data[key];
        }
      }
      return returnObj;
    };

    const saveForm = flow(function* saveForm(Id?: number) {
      const data = getDataForSave();
      const str = JSON.stringify(data);
      try {
        const response = yield updatePatientAdditionalInfoApi(Id || self.id, str);

        if (response) {
          self.selIsValid(true);
          return true;
        }
      } catch (error) {
        return false;
      }
    });

    function apply(state: any) {
      for (const key in state) {
        if (self[key as keyof typeof self]) {
          const value = state[key];
          if (self[key as keyof typeof self].type === "dateTime") {
            self[key as keyof typeof self].setValue(moment.utc(value).format("MM/DD/YYYY"));
          } if (self[key as keyof typeof self].type === "select") {
            const activeItem = self[key as keyof typeof self];
            let activeItemValue = null;
            if (activeItem.options && activeItem.options.length) {
              activeItemValue = activeItem.options.find((item: any) => item.value === state[key]);
            }
            if (activeItemValue) {
              self.setDefaultValueByKey(key, {
                value: "" + activeItemValue.value,
                label: "" + activeItemValue.label,
                isDisabled: false
              });
            } else {
              self.setDefaultValueByKey(key, {
                value: "" + state[key],
                label: "" + state[key],
                isDisabled: false
              });
            }
          } else {
            self[key as keyof typeof self].setValue(value + "");
          }
        }
      }
      updateLatestInitialState(state);
    }

    const validateFormFields = (isSaveIncomplete?: boolean): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;
      const fields = [
        "primaryInsuranceCompany",
        "policyNumber",
        "groupNumber",
        "secondaryInsuranceCompany",
        "secPolicyNumber",
        "secGroupNumber",
        "subscribersFirstName",
        "subscribersLastName",
        "subscribersRelationship",
        "subscribersDOB"
      ];
      if (self.subscribersRelationship?.value === "Other") {
        fields.push("specifyRelationship");
      }
      fields.forEach((field: string) => {
        const item = self[field as keyof typeof self];
        if (!item.isOptional) {
          if (item.type === "number") {
            if (item.value.indexOf("_") + 1) {
              isValid = false;
              errors[item.fieldId] = "Please enter valid phone number";
            }
          }
          if (item.validatePhone) {
            if (!validatePhoneHandler(item.value)) {
              isValid = false;
              errors[item.fieldId] = "Please enter valid phone number";
            }
          }
          if (!isSaveIncomplete) {
            if (!item?.value && (item.type === "input" || item.type === "dateTime")) {
              errors[field] = "Please fill the field";
              isValid = false;
            } else if (item.type === "dateTime") {
              if (item.value === "0001-01-01T00:00:00") {
                errors[field] = "Please fill the field";
                isValid = false;
              }
            } else if (item.type === "select" && (!item?.values && !item?.value)) {
              errors[field] = "Please fill the field";
              isValid = false;
            }
          }
          if (item.maxLength) {
            if (item.value.length > item.maxLength) {
              errors[field] = "Max length " + item.maxLength;
              isValid = false;
            }
          }
        }
      });
      return {
        errors,
        isValid
      };
    };

    async function init(data: any) {
      resetForm();
      self.id = data.patientId + "";
      if (!data.patientId) return;
      if (self.id) {
        self.selLoading(true);
      }
      self.setIsCheckingValid(true);
      await self.applyInsuranceCompanies();
      const info = await getPatientAdditionalInfoApi(data.patientId);
      if (info.ok && info.data) {
        const initState: any = {};
        const getValue = (name: string) => {
          if (info.data[name]) {
            return info.data[name];
          }
          return "";
        };
        const dobValue = getValue("SubscDOB");

        if (dobValue !== "0001-01-01T00:00:00") {
          initState.subscribersDOB = dobValue;
        } else {
          initState.subscribersDOB = "";
        }
        initState.primaryInsuranceCompany = getValue("PrimInsCompany");
        initState.policyNumber = getValue("PrimInsIDNumber");
        initState.groupNumber = getValue("PrimInsGroupNumber");
        initState.secondaryInsuranceCompany = getValue("SecInsCompany");
        initState.secPolicyNumber = getValue("SecInsIDNumber");
        initState.secGroupNumber = getValue("SecInsGroupNumber");
        initState.subscribersFirstName = getValue("SubscFirstName");
        initState.subscribersLastName = getValue("SubscLastName");
        initState.subscribersRelationship = getValue("SubscRelationship");
        initState.specifyRelationship = getValue("SpecifyRelationship");

        self.selLoading(false);
        apply(initState);
        self.setDisableCompany(initState.primaryInsuranceCompany, true);
        self.setDisableCompany(initState.secondaryInsuranceCompany, false);
        const isValid = validateFormFields().isValid;
        self.selIsValid(isValid);
        self.setIsCheckingValid(false);
      } else {
        self.selLoading(false);
        self.setIsCheckingValid(false);
      }
    }
    async function initNew(data: any) {
      applySnapshot(self, {
        ...AdditionalInfoInitialState,
        id: data.patientId + ""
      });
    }
    return {
      closeModal,
      init,
      initNew,
      resetForm,
      validateFormFields,
      saveForm
    };
  });

export interface IPatientCreateAdditionalModel extends Instance<typeof PatientCreateAdditionalModel> {}
export interface IPatientCreateAdditionalModelSnapShot extends SnapshotIn<typeof PatientCreateAdditionalModel> {}

import { CLReportsListHeaderWrapper } from "./cl-reports-list-header.presents";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLInput } from "../shared/cl-input";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import SearchIcon from "../../assets/icons/search.svg";
import { reportsListCountRows } from "../../boot/Constants";
import { CLPagination } from "../shared/cl-pagination";
import { CLIcon } from "../shared/cl-icon";
import CalendarIcon from "../../assets/icons/calendar.svg";
import moment from "moment";
import { DatePicker } from "antd";
import { CLSelect } from "../shared/cl-select";

const { RangePicker } = DatePicker;

export const CLReportsListHeader = observer(() => {
  const {
    setSearch,
    searchValue,
    getTotalCount,
    page,
    total,
    nextPage,
    previousPage,
    defaultPage,
    physiciansFilterOptions,
    getAllClinicPhysicians,
    modeOptions,
    signedOptions,
    selectedPhysicians,
    selectedMode,
    selectedSigned,
    selectePractice,
    selecteMode,
    selecteSigned,
    startDate,
    endDate,
    setDate
  } = useRootStore().listReportsStore;
  const {
    idPhysician
  } = useRootStore().userStore;
  const dateFormat = "MM/DD/yyyy";
  let startDateM = moment().subtract(6, "months");
  let endDateM = moment();

  useEffect(() => {
    if (!physiciansFilterOptions.length) {
      getAllClinicPhysicians().then((data: any) => {
        const active = data.find((option: any) => +option.value === +idPhysician);
        if (active) {
          selectePractice(active);
        }
      });
    }
  }, []);

  if (startDate && endDate) {
    startDateM = moment(startDate, dateFormat);
    endDateM = moment(endDate, dateFormat);
  }

  const defaultValues = selectedPhysicians.map((selectedValue: any) => {
    const active = physiciansFilterOptions.find((option: any) => +option.value === +selectedValue);
    return active;
  });
  const defaultValuesMode = selectedMode.map((selectedValue: any) => {
    const active = modeOptions.find((option: any) => +option.value === +selectedValue);
    return active;
  });
  const defaultValuesSigned = selectedSigned.map((selectedValue: any) => {
    const active = signedOptions.find((option: any) => +option.value === +selectedValue);
    return active;
  });

  const [state, setState] = useState<[any, any]>([startDateM, endDateM]);

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
    refreshData();
  };

  const refreshData = useMemo(
    () => debounce(getTotalCount, 1000), []);

  const onPickerChange = (values: any) => {
    setState(values);
    setDate(values ? values[0].format(dateFormat) : null, values ? values[1].format(dateFormat) : null);
    getTotalCount();
  };

  const practiceSelected = (event: any) => {
    defaultPage(true);
    selectePractice(event);
  };

  const modeSelected = (event: any) => {
    defaultPage(true);
    selecteMode(event);
  };
  const signedSelected = (event: any) => {
    defaultPage(true);
    selecteSigned(event);
  };

  return (
    <CLReportsListHeaderWrapper>
      <div className="group">
        <div className="margin-block-right">
          <CLInput
            handleChange={handleSearchChange}
            placeholder="Search"
            icon={SearchIcon}
            value={searchValue}
            width="375px"></CLInput>
        </div>
        <div className="pagination">
          <div className="date-picker">
            <div className="icon">
              <CLIcon icon={CalendarIcon}></CLIcon>
            </div>
            <RangePicker
              popupStyle={{ zIndex: 10500 }}
              defaultValue={state}
              format={dateFormat}
              onChange={onPickerChange}/>
          </div>
          {(total > reportsListCountRows) && <CLPagination
            total={total}
            page={page}
            countOnPage={reportsListCountRows}
            next={nextPage}
            previous={previousPage}></CLPagination>}
        </div>
      </div>

      <div className="filter-group">
        {physiciansFilterOptions.length > 0 && <CLSelect
          options={physiciansFilterOptions}
          padding={"16px"}
          defaultValue={defaultValues[0]}
          value={defaultValues[0]}
          isSearchable={false}
          isClearable={true}
          placeholder="All Physicians"
          selectOption={practiceSelected}></CLSelect>}
        <CLSelect
          options={modeOptions}
          padding={"16px"}
          defaultValue={defaultValuesMode[0]}
          value={defaultValuesMode[0]}
          isSearchable={false}
          isClearable={false}
          placeholder="Mode"
          selectOption={modeSelected}></CLSelect>
        <CLSelect
          options={signedOptions}
          padding={"16px"}
          defaultValue={defaultValuesSigned[0]}
          value={defaultValuesSigned[0]}
          isSearchable={false}
          isClearable={false}
          placeholder="Signed"
          selectOption={signedSelected}></CLSelect>
      </div>
    </CLReportsListHeaderWrapper>
  );
});
